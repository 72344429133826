import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import ig from '../images/icons/minnesota-hvac-company.svg';
import fb from '../images/icons/twin-cities-hvac-service-repair.svg';
import Helmet from 'react-helmet';
const SubFooter = (props) => (
  <div className="bg-gray-600 p-10">
    <div className="sub-footer h-40 md:flex text-white justify-around">
      <div className="flex">
        <a href="https://www.instagram.com/lasalle_heating/">
          <img src={ig} height="40" className="h-10 w-10" />
        </a>
        <a href="https://facebook.com/LaSalleHeating/">
          <img src={fb} height="40" className="h-10 w-10" />
        </a>
      </div>
      <ul>
        <li>
          <strong>Phone: </strong>
          {props.data.site.siteMetadata.contact.phone}
        </li>
        <li>
          <strong>Email: </strong>{' '}
          <a href={`mailto:${props.data.site.siteMetadata.contact.email}`}>
            {props.data.site.siteMetadata.contact.email}
          </a>
        </li>
      </ul>
      <ul>
        <li className="copyright">
          © {new Date().getFullYear()} {props.data.site.siteMetadata.title}
        </li>
        <li>
          <a href="http://localizedpro.com/">Site Design: Localized Pro</a>
        </li>
      </ul>
    </div>
  </div>
);

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            contact {
              email
              phone
            }
          }
        }
      }
    `}
    render={(data) => <SubFooter data={data} />}
  />
);
