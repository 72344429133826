import { Link } from 'gatsby';
import React from 'react';

export default function TxtCta({ headline1, headline2, txt, btnTxt, url }) {
  return (
    <div className="bg-gray-700 max-w-screen-xl mx-auto rounded-lg shadow-lg">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">{headline1}</span>
          <span className="block">{headline2}</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-gray-200">{txt}</p>
        <Link
          href={url}
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-600 bg-white hover:bg-indigo-50 sm:w-auto"
        >
          {btnTxt}
        </Link>
      </div>
    </div>
  );
}
