import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';
import TwHero from '../components/TwHero';
import TxtCta from '../components/twTxtCta';
import { InboxIcon, SparklesIcon } from '@heroicons/react/outline';
import {
  AnnotationIcon,
  GlobeAltIcon,
  StarIcon,
  ScaleIcon,
} from '@heroicons/react/outline';

const FAQ = () => {
  const features = [
    {
      name: `We've Got An Emergency HVAC Tech Near You!`,
      description: `In the Southern Twin Cities / South Metro? So are we! Located in Burnsville we've always got a 24/7 HVAC tech ready to go.`,
      icon: GlobeAltIcon,
    },
    {
      name: 'Whaddya Mean Whatever It Takes?',
      description: `Sometimes in the world of AC and Furnace repair doing the right thing isn't something a company can charge for. That's when our 'Whatever It Takes' motto comes into play.`,
      icon: ScaleIcon,
    },
    {
      name: 'We Have Hungreds Of REAL 5 Star Reviews.',
      description: `Thanks to all of our clients who took a moment to support our family owned business. We genuinely appreciate it.`,
      icon: StarIcon,
    },
    {
      name: 'Communication Is Key',
      description: `We are a very small company that works constantly. You knowing when we are coming and what we are doing is essential to you having the most normal day possible alongside your HVAC needs.`,
      icon: AnnotationIcon,
    },
  ];
  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-red-600 font-semibold tracking-wide uppercase">
            Need Emergency 24/7 Furnace / AC (Air Conditioning) Repair,
            Installation, Or Service?
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            We Will Do Whatever It Takes!
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Immediate Questions or AC / Furnace Repair Needs? <br />
            Call:
            <a href="tel:952-435-3633" className="text-blue-500">
              952-435-3633
            </a>
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

const Home = (props) => {
  const markdown = props.data.allMdx.edges;
  const json = props.data.allFeaturesJson.edges;
  const { heroBackground, referral, bannerAd, familyOwned } = props.data;

  return (
    <Layout>
      <SEO
        title="LaSalle | Furnace Repair, Air Conditioning Service, and Intallation"
        description="Furnace Repair, Air Conditioning Repair, and HVAC Installation. 24/7 emergency HVAC repair and service available."
      />
      <TwHero
        bg={heroBackground}
        headline="Minnesota AC Service & Installation"
        text="Since 1984 we have been a family-owned HVAC business operated out of our Burnsville, MN headquarters. We have a small team that will do whatever it takes to earn your business. AC or Furnace troubles? We can help!"
        btnURL="/hvac-service-installation-repair-scheduling"
        btnTxt="Schedule HVAC Service"
        featured={[
          {
            name: 'HVAC Housecall',
            color: 'red-800',
            href: '/hvac-service-installation-repair-scheduling',
            description: `HVAC units don't sleep! We offer emergency 24/7 emergency repair, inspection, and installation. If your AC or Furnace is doing anything out of the ordinary, let us know!`,
            icon: '/2022/icons/minnesota-hvac-service-repair-installation.svg',
            btnTxt: 'Get HVAC Support',
          },
          {
            name: 'Furnace Installation & Repairs',
            href: '/hvac-service-installation-repair-scheduling',
            color: 'orange-500',
            description: `Need a new furnace installation? We've got you covered. As a trusted Bryant® HVAC installation team, we've got you covered with the best HVAC units we've found to date. `,
            icon: '/2022/icons/minnesota-furnace-hvac-service-installation-repair.svg',
            btnTxt: 'Schedule Furnace or Heater Service',
          },
          {
            name: 'AC Installation, Service, Repair',
            href: '/hvac-service-installation-repair-scheduling',
            color: 'blue-500',
            description: `Our 3-month window of NEEDING a functioning air conditioning unit is upon us. Coincidentally this is also when they typically decide to die. If you either don't have one or have one on the fritz, get in touch. We can help with AC installations, inspections, and repairs.`,
            icon: '/2022/icons/best-ac-air-conditioning-service-repair-minnesota.svg',
            btnTxt: 'Schedule AC Service or Installation',
          },
        ]}
      />
      <TxtCta
        headline1="Qualified HVAC Pro?"
        headline2="We Are Hiring!"
        txt="If you are a qualified HVAC Pro we are hiring. We have installation, repair, and inspection positions available."
        btnTxt="Click Here To Apply"
        url="/employment"
      />

      <div className="rounded-lg shadow-lg overflow-hidden grid gap-10 grid-cols-1 md:grid-cols-2 max-w-screen-xl mx-auto my-20 bg-red-900">
        <div>
          <GatsbyImage
            className="h-full w-full"
            alt="best HVAC Company Twin Cities"
            image={referral.childImageSharp.gatsbyImageData}
          />
        </div>

        <div className="p-10">
          <h2 className="text-base font-semibold uppercase tracking-wider  max-w-full text-white">
            Try Our 2022 Referral Bonus!
          </h2>
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl max-w-full">
            Refer Family And Friends & Get A $100 Gift Card After Installation
          </p>
          <p className="mt-3 text-lg text-white ">
            We appreciate your business and would like to help those closest to
            you as well. Please remember to refer us to your family and friends.
            As a thanks, you'll receive a $100 VISA™ gift card after we complete
            the AC or Furnace installation.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="/referral"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                Click here to get started
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto">
        <div className="grid-cols-1 lg:max-w-9xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 items-center">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-orange-600">
                  <SparklesIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  AC and Furnace Repair
                </h1>
                <p className="mt-4 text-lg text-gray-500">
                  Family owned since 1984! LaSalle Heating and Air Conditioning
                  is located in Burnsville MN and offers services to:
                  <Link to="/furnace-repair-service-area/apple-valley">
                    Apple Valley
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/bloomington">
                    Bloomington
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/burnsville">
                    Burnsville
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/eagan">Eagan</Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/eden-prairie-hvac-company">
                    Eden Prairie
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/edina"> Edina</Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/farmington-hvac-company">
                    Farmington
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/inver-grove-heights-hvac-company">
                    Inver Grove Heights
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/lakeville">
                    Lakeville
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/mendota-heights-furnace-air-conditioning-repair">
                    Mendota Heights
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/new-market-hvac-company-ac-service">
                    New Market
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/prior-lake-ac-furnace-repair">
                    Prior Lake
                  </Link>
                  <span>, </span>
                  <Link to="/minnesota-hvac-company/richfield-ac-furnace-repair">
                    Richfield
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/rosemount">
                    Rosemount
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/savage">Savage</Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/shakopee">
                    Shakopee
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/south-st-paul">
                    South St. Paul
                  </Link>
                  <span>, </span>
                  <Link to="/furnace-repair-service-area/west-st-paul">
                    West St. Paul
                  </Link>
                  <span>, </span>and surrounding South Metro Minnesota areas.
                  (see service map below)
                </p>
                <p className="mt-4 text-lg text-gray-500">
                  We offer expert furnace repair, heating services, and AC
                  service/repair on existing residential systems. We also
                  service commercial HVAC systems as well as full replacement of
                  your heating, air conditioning and ventilation equipment.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <div className="w-full rounded-xl  shadow-xl ring-1 ring-black ring-opacity-5  lg:right-0 overflow-hidden lg:w-auto lg:max-w-none">
                <GatsbyImage
                  className="object-cover"
                  alt="best HVAC Company Twin Cities"
                  image={familyOwned.childImageSharp.gatsbyImageData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-screen-lg">
        <div className="row justify-content-start">
          <div className="w-full py-10">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              Minnesota HVAC Service, Repair, and Installation
            </h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 p-6 md:p-10">
            {markdown.map((edge, i) => (
              <div
                key={`${i}-${edge.node.frontmatter.path}`}
                className="shadow-lg rounded-md overflow-hidden"
              >
                <Link to={edge.node.frontmatter.path}>
                  <div className="grid grid-rows-2 ">
                    <div
                      className={`bg-${edge.node.frontmatter.color} h-full  w-full grid items-center justify-center text-center p-5`}
                    >
                      <img
                        src={edge.node.frontmatter.image}
                        className="w-1/3 mx-auto"
                      />
                    </div>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                      <h2 className="text-xl font-semibold text-gray-900">
                        {edge.node.frontmatter.title}
                      </h2>
                      <p className="mt-3 text-base text-gray-500">
                        {edge.node.excerpt}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="col-12 text-center">
            <Link
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              to="/residential-hvac"
            >
              View All Services
            </Link>
          </div>
        </div>
      </div>
      <FAQ />
      <div className="bg-gray-800 py-40">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl text-white">
            Need HVAC Service, Repair, or Installation?
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-300 sm:mt-4">
            We've got a few ways for you to reach out to us about your air
            conditioning, furnace, heater, or general HVAC needs:
          </p>
        </div>
        <div className="max-w-screen-xl mx-auto">
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {json.map((edge, i) => (
              <div
                key={`${i}-${edge.node.id}`}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden bg-white mx-4 md:mx-0"
              >
                <a href={edge.node.url}>
                  <div className="feature">
                    {edge.node.image && (
                      <div className="grid items-center justify-center bg-gray-200 p-10">
                        <img
                          className="h-20"
                          src={withPrefix(edge.node.image)}
                        />
                      </div>
                    )}
                    <div className="p-10">
                      <h2 className="feature-title">{edge.node.title}</h2>
                      <div className="feature-content">
                        {edge.node.description}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <div className="mx-auto w-full grid items-center justify-center p-2 py-10 md:p-40">
          <Link to="/financing">
            <GatsbyImage
              alt="twin cities hvac financing"
              image={bannerAd.childImageSharp.gatsbyImageData}
            />
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            image
            color
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
          url
        }
      }
    }
    heroBackground: file(
      relativePath: {
        eq: "air-conditioning-furnace-repair-service-minnesota.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    familyOwned: file(
      relativePath: {
        eq: "home-air-conditioning-ac-repair-service-installation.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    referral: file(
      relativePath: {
        eq: "mn-home-furnace-heating-service-repair-installation.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    bannerAd: file(
      relativePath: { eq: "minnesota-hvac-furnace-repair-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Home;
