import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';

const Call = (props) => (
  <div className="max-w-screen-2xl mx-auto text-center py-20">
    {props.button && (
      <div className="call-box-top mx-auto text-center">
        <div className="call-phone text-center">
          <h4 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
            In Need Of Furnace Repair, Service, or Installation?
          </h4>
          <p className="text-lg">
            We also offer 24/7 Emergency HVAC Service, Inspections,
            Installation, and Repair
          </p>
          <div className="mt-9 text-center mx-auto max-w-96 w-96">
            <div className="flex text-center">
              <div className="flex-shrink-0 text-center">
                <a href={`tel:${props.data.site.siteMetadata.contact.phone}`}>
                  <PhoneIcon
                    className="h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </a>
              </div>
              <div className="ml-3 text-base text-gray-500 text-center">
                <p>
                  <a href={`tel:${props.data.site.siteMetadata.contact.phone}`}>
                    {props.data.site.siteMetadata.contact.phone}
                  </a>
                </p>
                <p className="mt-1">Mon-Fri 24/7</p>
              </div>
            </div>
          </div>
          <div className="mt-6 flex max-w-96 mx-auto w-96">
            <div className="flex-shrink-0">
              <a href={`mailto:${props.data.site.siteMetadata.contact.email}`}>
                <MailIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </a>
            </div>
            <div className="ml-3 text-base text-gray-500">
              <a href={`mailto:${props.data.site.siteMetadata.contact.email}`}>
                {props.data.site.siteMetadata.contact.email}
              </a>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            contact {
              email
              phone
            }
          }
        }
      }
    `}
    render={(data) => <Call button={props.button} data={data} />}
  />
);
