import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import logo from '../images/furnace-repair-service.png';
import bbb from '../images/minnesota-hvac-company.png';
import energy from '../images/hvac-service-repair.jpg';
import nate from '../images/air-conditioning-service-repair-minnesota.jpg';
import bryant from '../images/hvac-service-repair-minnesota.jpg';
import fargo from '../images/ac-furnance-minnesota-repair-3.jpg';

const Footer = (props) => (
  <div>
    <div className="footer-strip grid-cols-1 py-40 bg-gray-100">
      <div className=" mix-blend-multiply grid items-center justify-center max-w-4xl mx-auto">
        <img
          className="min-w-full"
          alt="Furnace Repair Service - Air Conditioning Service Repair"
          src={logo}
        />
      </div>
      <div>
        <ul className="grid grid-cols-2 md:grid-cols-3 justify-center gap-12 pt-10 max-w-4xl mx-auto px-10">
          <li className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500">
            <Link to="/residential-hvac">See HVAC Services</Link>
          </li>
          <li className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500">
            <Link to="/hvac-service-areas">Service Area</Link>
          </li>
          <li className="col-span-2 md:col-span-1 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-800 hover:bg-red-600">
            <Link to="/hvac-service-installation-repair-scheduling">
              Schedule Service
            </Link>
          </li>
        </ul>
      </div>
    </div>
    <div className="w-full">
      <div className="w-full bg-blue-100 p-10 md:p-20 ">
        <div className="grid grid-cols-2 md:grid-cols-5  gap-10 items-center justify-around">
          <div className="mix-blend-multiply grid items-center justify-center">
            <img src={bbb} alt="minnesota hvac company" className="lg:h-28" />
          </div>
          {/* <img src={energy} height="100px" className="pl-2" alt="hvac service repair"/> */}
          <div className="mix-blend-multiply grid items-center justify-center">
            <img
              src={nate}
              alt="air conditioning service repair minnesota"
              className="lg:h-28"
            />
          </div>
          <div className="mix-blend-multiply grid items-center justify-center">
            <img
              src={bryant}
              alt="hvac service repair minnesota"
              className="lg:h-28"
            />
          </div>
          <div className="mix-blend-multiply grid items-center justify-center">
            <img
              alt="LaSalle Heating and Air Conditioning, Inc. is a Top Rated HomeAdvisor Pro"
              src="https://www.homeadvisor.com/images/sp-badges/toprated-solid-border.png?sp=63397360&key=9b1a970930af57bf4eb878d5ab987cf8"
              className="lg:h-28"
            />
          </div>
          <div className="mix-blend-multiply grid items-center justify-center col-span-2 md:col-span-1">
            <div>
              <Link to="/financing" className="financing-banner">
                <img
                  src={fargo}
                  alt="hvac service repair minnesota"
                  className="lg:h-28"
                />
              </Link>
            </div>
          </div>
        </div>
        <div id="nn-reviews"></div>
      </div>
    </div>
  </div>
);

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => <Footer data={data} />}
  />
);
